<template>
  <div class="d-flex align-center">
    <v-menu
      offset-x
      right
      open-on-hover
      nudge-right="20px"
    >
      <template #activator="{ on, attrs }">
        <v-badge
          :content="devices.length > 1 ? devices.length.toString() : ''"
          :value="devices.length > 1"
          color="red"
          overlap
          bordered
          offset-x="8"
          offset-y="8"
        >
          <v-icon
            v-bind="attrs"
            v-on="on"
            small
            :x-small="xSmall"
            v-html="icon(devices[0])"
            :color="devices.length > 1 ? 'red' : null"
          />
        </v-badge>

        <evalmee-chip
          v-show="showInlineVmScore"
          v-bind="attrs"
          v-on="on"
          small
          label
          class="ml-1"
          icon="mdi-incognito"
          :color="vmWarning(devices[0]?.virtual_machine_score) ? 'red' : 'grey'"
        >
          {{ devices[0]?.virtual_machine_score }}
        </evalmee-chip>
      </template>
      <v-sheet
        class="d-flex"
        max-width="550"
      >
        <v-row
          no-gutters
        >
          <v-col
            v-for="device in devices"
            :key="device.id"
            cols="auto"
          >
            <user-device-card :device="device" />
          </v-col>
        </v-row>
      </v-sheet>
    </v-menu>

    <v-icon
      v-if="devices.length === 0"
      small
      color="grey lighten-1"
    >
      mdi-cellphone-link
    </v-icon>
  </div>
</template>

<script>
import UserDeviceCard from "./user_device_card.vue"
import userDeviceMixin from "./user_device_mixin"
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"

export default {
  name: "UserDevices",
  components: { EvalmeeChip, UserDeviceCard },
  mixins: [userDeviceMixin],
  props: {
    // Array of Quizzes::StudentEvents::Device serialized from backend
    devices : {
      type: Array,
      default: () => [],
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    showInlineVmScore: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.devices.length > 1 ? "red" : null
    },
    vmWarning() {
      return score => score > 5
    },
  },
}
</script>
